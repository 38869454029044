import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { elementAction, feedbackAction } from "../../store";
import Uwp, { UploadFileStatusEnum } from "../../utils/uwp";
import { Keyboard } from "../control";
import { Button } from "../elements";
import ErrorIcon from "../elements/icon/error";

export default function SubmitDialog(props) {
  const { id, t } = props;
  const dispatch = useDispatch();
  // const [isRedirectMSStore, setIsRedirectMSStore] = React.useState(false);
  const submitSuccess = useSelector((state) => state.feedback.submitSuccess);
  const submitStatus = useSelector((state) => state.feedback.submitStatus);
  const uploadStatus = useSelector((state) => state.uwp.uploadStatus);

  const totalProgress = useSelector((state) => state.feedback.totalProgress);
  const theme = useSelector((state) => state.element.theme);
  useEffect(() => {
    document.getElementById(id).addEventListener("cancel", (event) => {
      event.preventDefault();
      document.getElementById("submit").focus();
    });
    document.getElementById(`${id}-overlay`).addEventListener(
      "touchmove",
      (e) => {
        e.preventDefault();
      },
      { passive: false }
    );
    // if (submitStatus === 4) {
    //   setTimeout(() => {
    //     if (isRedirectMSStore) {
    //       return;
    //     }
    //     Uwp.redirectToMSSTore("");
    //     setIsRedirectMSStore(true);
    //   }, 3000);
    // }
  });
  useEffect(() => {
    if (totalProgress === 100) {
      dispatch(feedbackAction.setAttachmentFileUploadDone());
    }
  }, [totalProgress]);
  // const resetForm = () => {dispatch(feedbackAction.initFeedback())}
  // const closeDialog = () => {dispatch(elementAction.triggerDialog({id, isOpen: false}))}

  // const getCountryCode = async () => {
  //   const countryCode = await AppClient.getCountry()
  //   dispatch(feedbackAction.setGeoCountry(countryCode))
  // }
  async function confirm() {
    await Uwp.clearWebContent();
    Uwp.redirectToSettingsPage();
    window.location.href = "/ac-feedback-hub";
  }

  async function submit() {
    // eslint-disable-next-line default-case
    switch (submitStatus) {
      case 0:
        if (Uwp.getBridge()) {
          await Uwp.cancelUploadLogFile();
        }
        break;
      case 1:
        if (Uwp.getBridge()) {
          await Uwp.resetUploadLogFileStatus();
        }
        dispatch(feedbackAction.initFeedback());
        Uwp.redirectToSettingsPage();
        break;
      case 2:
        dispatch(feedbackAction.submitInit());
        await confirm();
        break;
      case 3:
        dispatch(feedbackAction.submitInit());
        await confirm();
        break;
      case 4:
        await Uwp.redirectToMSSTore("");
        window.location.href = "/ac-feedback-hub/like-confirm";
        break;
    }
    dispatch(feedbackAction.setLogUploadProgress(0));
    dispatch(elementAction.triggerDialog({ id, isOpen: false }));
  }

  const keyEnter = () => {
    submit();
  };

  const keyEsc = () => {
    submit();
  };

  return (
    <dialog id={id} className="bg-transparent font-sans antialiased">
      <Keyboard options={[]} keyEnter={keyEnter} keyEsc={keyEsc} selected={0} />
      <div
        id={`${id}-overlay`}
        className={`fixed right-0 left-0 top-0 bottom-0 bg-${theme} bg-opacity-80	`}
      />
      <div
        className={`w-[50vw] h-[450px] bg-${theme} relative flex flex-col border-[1px] border-${theme}-checkbox-disabled `}
      >
        <div
          className={`absolute w-2 h-2 left-[0px] top-[0px]
           group-hover:hidden group-active:border-btn-click-light
               border-t-[1px] border-l-[1px]`}
        />
        <div
          className={`absolute w-2 h-2 left-[0px] bottom-[0px]
            group-hover:hidden group-active:border-btn-click-light
               border-b-[1px] border-l-[1px] `}
        />
        <div
          className={`absolute w-2 h-2 right-[0px] bottom-[0px]
            group-hover:hidden group-active:border-btn-click-light
               border-b-[1px] border-r-[1px]`}
        />
        <div
          className={`w-full col-start-1 ${
            submitSuccess ? "h-[100px]" : "h-[125px]"
          }`}
        >
          <div className={`px-6 py-6 text-${theme}-normal`}>
            {submitStatus === 0 && (
              <>
                <p className="text-2xl font-semibold text-left">
                  {t("submitSuccess")}
                </p>
                <p className="text-xl mt-3 font-ligh text-center">
                  {t("submitProcessing")}
                </p>
              </>
            )}
            {submitStatus === 1 && (
              <p className="text-2xl text-center">{t("submitProcessDone")}</p>
            )}
            {(submitStatus === 2 || submitStatus === 3) && (
              <>
                <p className="text-2xl text-left">
                  {submitStatus === 2 && t("submitFailed")}
                  {submitStatus === 3 && t("file_upload_failed")}
                </p>
                <ErrorIcon className={"w-full justify-center flex"} />
                <p className="text-xl mt-3 font-light text-center mt-5 px-[80px]">
                  {submitStatus === 2 && t("submitFailedContent")}
                  {submitStatus === 3 && t("file_upload_failed_content")}
                </p>
              </>
            )}
            {submitStatus === 4 && (
              <p className="text-2xl mt-[50px] ml-[-50px] font-light  px-[80px]">
                {t("like_submit_message")}
              </p>
            )}
          </div>
        </div>
        {/* Progressbar Start */}
        {submitStatus === 0 && (
          <div className={`bg-gray-600 h-[6px] mt-[-1vh] mx-6 rounded`}>
            <div
              className={`bg-rog-red h-full`}
              style={{ width: `${totalProgress}%` }}
            />
          </div>
        )}
        {/* Progressbar End */}
        {/* Button Start */}
        <div
          className={`group bg-transparent w-full h-[48px] flex ${
            submitStatus === 4 ? "justify-end pr-[60px]" : "justify-center"
          } items-center absolute bottom-[40px]
          `}
        >
          {submitStatus === 0 &&
            uploadStatus > UploadFileStatusEnum.PackageEnd &&
            (uploadStatus !== UploadFileStatusEnum.Canceled ||
              uploadStatus !== UploadFileStatusEnum.Canceling) && (
              <Button
                id={"submit"}
                className={`bg-${theme}-btn-enabled h-full w-[166px] text-2xl px-4 border border-${theme}-normal text-${theme}-normal font-semibold relative group-hover:border-4 group-hover:border-btn-hover-light group-active:bg-btn-click group-active:border-btn-click`}
                onClick={submit}
              >
                {t("cancel")}
              </Button>
            )}
          {submitStatus > 0 && (
            <Button
              id={"submit"}
              isFocus={(name) => false}
              theme={theme}
              isEnabled={true}
              className={`bg-${theme}-btn-disabled h-full w-[166px] text-2xl px-4 border border-${theme}-normal text-${theme}-normal font-semibold relative group-hover:border-4 group-hover:border-btn-hover-light group-active:bg-btn-click group-active:border-btn-click`}
              onClick={submit}
            >
              {(submitStatus === 1 || submitStatus === 4) && t("done")}
              {(submitStatus === 2 || submitStatus === 3) && t("ok")}
            </Button>
          )}
        </div>

        {/* Button End */}
        {/* Bottom Start */}
        {/*<div*/}
        {/*  className="mt-auto w-[600px] h-[70px] bg-dialog-bottom bg-cover"/>*/}
        {/* Bottom End */}
      </div>
    </dialog>
  );
}
