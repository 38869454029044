import { createSlice } from "@reduxjs/toolkit";
import {
  checkPrivacyPolicyAbortAge,
  formatDateToUTC,
  ValidateEmail,
} from "../utils/format";

export const feedback = createSlice({
  name: "feedback",
  initialState: {
    geoCountry: "",
    privacyPolicyAbortAge: 0,
    email: "",
    category_id: "0",
    categories: [],
    description: "",
    agreePrivacyPolicy: false,
    logUploadProgress: 0,
    customFileUploadProgress: 0,
    totalProgress: 0,
    haveCustomFile: false,
    attachmentFileUploadDone: false,
    enableSubmit: false,
    submitStatus: 0,
    privacyNoticeAgreedAt: null,
  },
  reducers: {
    initFeedback: (state) => {
      state.email = "";
      state.categoriies = [];
      state.emailCheck = true;
      state.category_id = "";
      state.description = "";
      state.agreePrivacyPolicy = false;
      state.logUploadProgress = 0;
      state.customFileUploadProgress = 0;
      state.totalProgress = 0;
      state.haveCustomFile = false;
      state.submitSuccess = false;
      state.submitStatus = 0;
      state.attachmentFileUploadDone = false;
      state.enableSubmit = false;
      state.privacyNoticeAgreedAt = null;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setCategory: (state, action) => {
      state.category_id = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setAgreePrivacyPolicy: (state, action) => {
      state.agreePrivacyPolicy = action.payload;
    },
    setPrivacyNoticeAgreedAt: (state) => {
      state.privacyNoticeAgreedAt = formatDateToUTC(new Date());
    },
    submitInit: (state) => {
      state.submitStatus = 0;
    },
    submitFailed: (state) => {
      state.submitStatus = 2;
      state.submitSuccess = false;
    },
    uploadFileError: (state) => {
      state.submitStatus = 3;
    },
    submitLike: (state) => {
      state.submitStatus = 4;
    },
    submitSuccess: (state) => {
      state.submitStatus = 1;
      state.submitSuccess = true;
    },
    setLogUploadProgress: (state, action) => {
      state.logUploadProgress = action.payload;
      if (state.haveCustomFile) {
        state.totalProgress =
          state.logUploadProgress * 0.5 + state.customFileUploadProgress * 0.5;
      } else {
        state.totalProgress = state.logUploadProgress;
      }
    },
    setCustomFileUploadProgress: (state, action) => {
      state.customFileUploadProgress = action.payload;
      if (state.haveCustomFile) {
        state.totalProgress =
          state.logUploadProgress * 0.5 + state.customFileUploadProgress * 0.5;
      }
    },
    setHaveCustomFile: (state, action) => {
      state.haveCustomFile = action.payload;
    },
    setAttachmentFileUploadDone: (state) => {
      state.attachmentFileUploadDone = true;
    },
    checkSubmit: (state) => {
      if (state.email === "") {
        state.enableSubmit = false;
        state.emailCheck = false;
        return;
      }
      if (state.email !== "") {
        if (!ValidateEmail(state.email)) {
          state.enableSubmit = false;
          return;
        }
      }
      state.emailCheck = true;
      if (state.category_id === "") {
        state.enableSubmit = false;
        return;
      }
      if (state.category_id === "0") {
        state.enableSubmit = false;
        return;
      }
      if (!state.agreePrivacyPolicy) {
        state.enableSubmit = false;
        return;
      }
      if (state.description === "") {
        state.enableSubmit = false;
        return;
      }
      state.enableSubmit = true;
    },
    setGeoCountry: (state, action) => {
      // console.log('set Geo Country', action.payload)
      state.geoCountry = action.payload;
      if (state.geoCountry !== "") {
        state.privacyPolicyAbortAge = checkPrivacyPolicyAbortAge(
          state.geoCountry
        );
      } else {
        state.privacyPolicyAbortAge = 0;
      }
    },
  },
});

export default feedback.reducer;
